.section-realestate-top {
    padding-top: 25px;
}

#property {
    .preloader-similar,
    .preloader {
        position: absolute;
        left: 0;
        right: 0;
        top: calc(50% - 36px);
        z-index: 999;
    }
}

.property-intro {
    h1 {
        font-size: 30px;
        font-weight: 300;
        line-height: 1.1;
        word-break: break-word;
    }

    p {
        font-size: 16px;
        font-weight: 400;
        color: #777;

        &.property-location {
            margin-top: 0;
            margin-bottom: 0;
        }

        &.price {
            font-size: 28px;
            font-weight: 600;
            color: #555;
            position: relative;

            small {
                font-size: 14px;
                font-weight: 300;
                text-decoration: line-through;
                color: #777;
                position: absolute;
                left: 0;
                top: -10px;

                &.other-currency {
                    font-size: 16px;
                    font-weight: 400;
                    text-decoration: none;
                    position: relative;
                    margin-left: 15px;
                    top: 0;
                }
            }
        }
    }

    ul {
        color: #555;

        &.property-actions {
            font-size: 13px;
            margin-top: 1rem;

            li {
                &:not(:last-child) {
                    margin-right: 1rem;
                }

                i {
                    font-size: 16px;
                    color: color('single-view-icon', '', '', $colors-single-view);
                    margin-right: 5px;
                }

                .pdf-download {
                    i {
                        margin-left: 5px;
                        margin-right: 0;
                    }
                }

                a {
                    &:hover {
                        text-decoration: none;
                        color: color('primary');
                    }
                }
            }

            .dropdown {
                .dropdown-menu {
                    border: none;
                    border-radius: 0;
                    padding: 0;
                    box-shadow:
                        0 2.9px 2.2px rgba(0, 0, 0, 0.039),
                        0 6.9px 5.3px rgba(0, 0, 0, 0.057),
                        0 13px 10px rgba(0, 0, 0, 0.07),
                        0 23.2px 17.9px rgba(0, 0, 0, 0.083),
                        0 43.4px 33.4px rgba(0, 0, 0, 0.101),
                        0 104px 80px rgba(0, 0, 0, 0.14);

                    .dropdown-item {
                        display: block;
                        padding: 0.7rem 1rem 0;

                        &:hover {
                            background-color: transparent;
                            color: rgba(105, 105, 105, 0.966);
                        }

                        &:after {
                            content : "";
                            display: block;
                            border-bottom: 1px solid #d4d4d4;
                            margin-top: 0.7rem;
                        }

                        &:last-child {
                            padding-bottom: 0.7rem;

                            &:after {
                                display: none;
                            }
                        }
                    }
                }

                &.pdf-dropdown {
                    button {
                        border: none;
                    }
                }
            }

            .pdf-list-item {
                float: right;
            }
        }

        .property-id-icon {
            background-image: url("../../images/icons/property-id.png");
            background-repeat: no-repeat;
            background-position: left 2px;
            padding-left: 19px;
        }

        &.property-details {
            font-size: 15px;
            font-weight: 600;
            margin-bottom: 30px;
            
            li {
                margin-right: 20px;

                &:last-of-type {
                    margin-right: 0;
                }
            }

            .property-bed-icon,
            .property-bathroom-icon,
            .property-area-icon,
            .property-floor-icon {
                background-repeat: no-repeat;
                background-position: left center;
                padding-left: 23px;
                background-size: 16px 16px;
            }

            .property-bed-icon {
                background-image: url("../../images/icons/bed.png");
                padding-left: 28px;
            }

            .property-bathroom-icon {
                background-image: url("../../images/icons/bathroom.png");
            }

            .property-area-icon {
                background-image: url("../../images/icons/area.png");
            }

            .property-floor-icon {
                background-image: url("../../images/icons/floor.png");
            }
        }
    }
}

.property-specialty {
    li {
        font-size: 12px;
        line-height: 1;
        color: #FFF;
        padding: 2px 4px 4px;

        &:nth-child(1), :nth-child(8){
            background-color: #50b93b;
        }
        &:nth-child(2), :nth-child(9){
            background-color: #d3ad2a;
        }
        &:nth-child(3), :nth-child(10){
            background-color: #e75e5e;
        }
        &:nth-child(4), :nth-child(11){
            background-color: #3b6bb9;
        }
        &:nth-child(5), :nth-child(12){
            background-color: #9e0142;
        }
        &:nth-child(6), :nth-child(13){
            background-color: #FFA500;
        }
        &:nth-child(7), :nth-child(14){
            background-color: #b33bb9;
        }
    }
}

.property-information {
    .col {
        // margin-bottom: 50px;
        ul {
            margin-bottom: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    ul {
        li {
            font-size: 15px;
            font-weight: 400;
            margin-bottom: 10px;

            &:last-of-type {
                margin-bottom: 0;
            }

            span {
                display: inline-block;
                font-weight: 500;
                width: 270px;
            }

            i {
                margin-left: 5px;

                &.fa-check {
                    color: #9BBC01;
                }
            }
        }
    }
}

.property-description {
    scrollbar-width: none;

    ul,
    p {
        font-size: 15px;
        font-weight: 400;
        line-height: 1.5;
        color: #777;
    }

    &.excerpt {
        -webkit-mask-image: linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255, 0) 90%);
        mask-image: linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255, 0) 90%);
        height:  120px;
        transition: 1s;
        overflow: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    &.show {
        display: block;
        // height: 300px;
        -webkit-mask-image: none;
        mask-image: none;
        transition: 1s ease-out;
    }
}

.accordion-btn {
    display: block;
    margin: auto;

    .fa-angle-down {
        font-size: 30px;
        display: block;
        text-align: center;
        cursor: pointer;
        transition: 1s;

        &.rotate {
            transform: rotateZ(180deg);
            transition: 0.5s;
        }
    }
}

.ground-plans-trigger {
    font-size: 15px;
    color: #555555;
    position: absolute;
    top: 3px;
    right: 15px;

    i {
        font-size: 16px;
        margin-right: 5px;
    }

    &:hover {
        text-decoration: none;
        color: color('primary');
    }
}

.ground-plans-wrapper {
    img {
        max-width: 100%;
        height: auto;
    }
}

.company-contact {
    li {
        font-weight: 300;
        font-size: 15px;
        margin-bottom: 20px;
        padding-left: 30px;
        position: relative;

        i {
            display: inline-block;
            font-size: 16px;
            width: 15px;
            margin-right: 10px;
            position: absolute;
            left: 0;
            top: 4px;
            color: color('secondary');
        }

        a {
            &:hover {
                text-decoration: none;
            }
        }
    }
}

.agent-contact {
    .far.fa-address-book {
        margin-right: 5px;
        font-size: 16px;
        color: color('secondary');
    }
}

.agents {
    margin-top: 50px;

    .agent-box {
        margin-bottom: 25px;

        .agent-image {
            display: inline-block;
            width: 30%;
            margin-right: 15px;
            vertical-align: top;

            img {
                margin-top: 5px;
            }
        }

        ul {
            display: inline-block;
            width: 60%;
            font-size: 15px;
            font-weight: 300;
            margin: 0;

            .agent-name {
                font-weight: 600;
                // margin-bottom: 20px;
            }

            li {
               margin-top: 5px;

                i {
                    color: color('primary');
                    font-size: 14px;
                    margin-right: 5px;
                    position: relative;
                    top: 1px;
                }
            }
        }
    }
}
.section {
    .property-section{
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }
}
.property-section {
    border-bottom: 1px solid #d4d4d4;

    &:nth-child(2),
    &:first-of-type,
    &:last-of-type {
        border: 0;
    }

    &.bg {
        background: #f5f3f2;
    }

    h2 {
        font-size: 24px;
        font-weight: 500;
        color: #555555;
        margin-bottom: 30px;
    }
}

button {
    &.btn {
        &.btn-big {
            font-size: 14px;
            padding: 10px 15px;

            i {
                margin-left: 15px;
                font-size: 16px;
            }
        }
    }
}

.add-to-favorites {
    &:hover,
    &.added {
        i {
            color: color('error') !important;
            font-weight: 900;
            text-shadow: 2px 2px 8px rgba(255, 255, 255, 0.5);
            font-size: 17px;
            transition: all .3s ease-in;
        }
    }
}
