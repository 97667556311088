.sticky-div {
    background: #eaeaea;
}

#property-group-view {
    #map-canvas {
        .property-card {
            margin-bottom: 1rem;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

#property-map-view {
    #map-canvas {
        .property-card {
            margin-bottom: 1rem;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

