.user-settings-modul {

  form {

    #web_user {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;

      @media(max-width: 768px) {
        flex-direction: row;
        justify-content: center;
      }

      .form-group {
        width: 47%;

        @media(max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
}

.my-account-icons {
    @media(max-width: 640px) {
        justify-content: center;
    }

  .icon-wrapper {
    width: 250px;
    height: 200px;

    @media(max-width: 640px) {
      width: 100%;
    }
  }

    a {
        display: block;
        text-decoration: none;
        border: 1px solid #d4d4d4;
        color: color('primary');

        &:hover {
            color: color('primary');
            border-color: color('primary');
        }

        .my-account-icons-content {
            text-align: center;
            padding: 30px;

            i {
                font-size: 80px;
            }

            h5 {
                padding-top: 20px;
                font-size: 15px;
            }
        }
    }
}

.notification-modal {
    .modal-content {
        background-color: #fefefe;
        margin: 15% auto;
        border: 1px solid rgba(color('primary'), 0.3);
        width: 450px;

        @media(max-width: 768px) {
            width: 400px;
        }

        @media(max-width: 425px) {
            width: 320px;
        }

        .modal-top {
            padding: 10px 20px;
            background-color: color('primary');
            color: #eee;

            p {
                margin: 0;
                color: #fff;
                font-size: 20px;
                font-weight: 300;
                width: 90%;
                line-height: 2;
            }

            button {
                color: #fff;

                span {
                    font-size: 40px;
                }
            }
        }

        .return-message {
            margin: 20px 0 0 20px;
            font-size: 20px;

            &.success {
                color: color('primary');
                font-weight: 500;
            }

            &.error {
                color: #b04141;
            }
        }

        #stored_search_frequency {
            padding: 20px 20px 10px 20px;

            label {
                font-size: 15px;
            }
        }

        .save-frequency-btn {
            padding: 8px 18px;
            margin: 0px 20px 20px 20px;
            text-transform: uppercase;
            color: #fff;
            background-color: color('primary');
            font-size: 13px;
            font-weight: 300;

            i {
                padding-left: 10px;

                &::before {
                    content: "\f0c7";
                }
            }

            &[disabled] {
                background: #8896b0;
            }

            &[disabled]:hover {
                cursor: default;
            }
        }
    }

    select {
        padding: 8px;
        border-radius: 0;
        height: auto;
    }
}

#send-to-friend-modal {
    .modal-content {
        background-color: #fefefe;
        margin: 15% auto;
        border: 1px solid rgba(color('primary'), 0.3);
        width: 500px;

        @media(max-width: 768px) {
            width: 400px;
        }

        @media(max-width: 425px) {
            width: 320px;
        }

        .modal-top {
            padding: 10px 20px;
            background-color: color('primary');
            color: #eee;

            p {
                margin: 0;
                color: #fff;
                font-size: 20px;
                font-weight: 300;
                width: 90%;
                line-height: 2;
            }

            button {
                color: #fff;

                span {
                    font-size: 40px;
                }
            }
        }

        form {
            .send-to-friend-wrapper {
                font-weight: 600;
                border-bottom: 1px solid rgba(128, 128, 128, 0.3);
                margin-bottom: 25px;

                .input-wrapper {
                    position: relative;

                    &::after {
                        position: absolute;
                        z-index: 50;
                        top: -8px;
                        right: -5px;
                        font-family: 'Font Awesome 5 Free';
                        font-weight: 900;
                        font-size: 9px;
                        content: "\f005";
                        color: #151e3a;
                    }
                }

                .additional-email {
                    display: none;
                    margin-bottom: 30px;

                    &.show {
                        display: block;
                    }
                }

                input[type="email"] {
                    padding: 8px 16px;
                    margin-left: 25px;
                    margin-bottom: 30px;
                    margin-right: 0;
                    width: calc(100% - 25px);
                    border: 1px solid rgba(128, 128, 128, 0.3);

                    &:first-of-type {
                        margin-bottom: 0;
                    }
                }

                p {
                    width: 30%;
                    margin: 0 0 0 25px;
                    font-size: 12px;
                    cursor: pointer;

                    &:hover {
                        color: #000000;
                    }

                    span {
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 1.5;
                    }
                }
            }

            input[type="radio"],
            input[type="checkbox"] {
                margin-right: 10px;
                border: 1px solid rgba(128, 128, 128, 0.3);
            }

            .send-query {
                padding-bottom: 25px;
                font-weight: 600;
                border-bottom: 1px solid rgba(128, 128, 128, 0.3);
            }

            .send-copy-checkbox {
                input {
                    position: relative;
                    -webkit-appearance: none;
                    background-color: white;
                    border: 0.5px solid rgba(128, 128, 128, 0.3);
                    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
                    padding: 8px;
                    margin-bottom: 0;
                    margin-right: 10px;
                    vertical-align: middle;
                    -moz-user-focus: ignore;

                    &:hover {
                        cursor: pointer;
                    }

                    &:checked {
                        background-color: white;
                        border: 1px solid #adb8c0;
                        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
                        color: #99a1a7;
                    }

                    &:checked:after {
                        content: "";
                        position: absolute;
                        left: 3px;
                        top: 0px;
                        width: 8px;
                        height: 13px;
                        border: solid #4d4d4d;
                        border-width: 0 3px 3px 0;
                        -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                }
            }

            .message-wrapper {
                position: relative;

                &::after {
                    position: absolute;
                    z-index: 50;
                    top: 3px;
                    right: -5px;
                    font-family: 'Font Awesome 5 Free';
                    font-weight: 900;
                    font-size: 9px;
                    content: "\f005";
                    color: #151e3a;
                }

                input {
                    padding: 5px 16px;
                    margin-bottom: 10px;
                    border: 1px solid rgba(128, 128, 128, 0.3);
                }

                textarea {
                    margin-top: 10px;
                    width: 100%;
                    height: 100px;
                    padding: 15px;
                    border: 1px solid rgba(128, 128, 128, 0.3);
                }
            }

            .btn-send-email {
                padding: 8px 18px;
                text-transform: uppercase;
                color: #fff;
                background-color: color('primary');
                font-size: 13px;
                font-weight: 300;

                i {
                    padding-left: 10px;

                    &:before {
                        content: "\f0e0";
                    }
                }
            }
        }

        p {
            font-size: 13px;
        }

        span {
            padding: 5px;
            position: relative;

            .fas.fa-star {
                &::before {
                    position: absolute;
                    top: 8px;
                    left: 0;
                    content: "\f005";
                    font-size: 9px;
                    color: #151e3a;
                }
            }
        }
    }
}

.stored-search {
    > div {

        &.even {
            .stored-item {
                background: #f1f1f1;
            }
        }

        .stored-item {
            align-items: center;
            background: #f9f9f9;
            width: 100%;
        }

        @media(max-width: 575px) {
            margin-bottom: 30px;
        }

        a {
            display: block;
            width: 100%;
            padding: 8px 0;
            margin: 5px 0;
            text-align: center;
            color: #eeee;
            text-decoration: none;

            &.stored-search-name {
                text-align: start;
                padding-left: 10px;
                padding-right: 10px;
                color: #000000;
                line-break: anywhere;

                &:hover {
                    color: color('primary');
                    background: none;
                }
            }

            &.stored-search-notification {
                background: #333333;
            }

            &.erase-stored-search {
                background: #b03838;
            }

            &:hover {
                background: #315dc1;
            }

            i {
                margin-left: 5px;
                //color: color('secondary');
            }

            .fas.fa-bell {
                margin-left: 10px;
                //color: color('secondary');
            }

            .fas.fa-times-circle {}
        }
    }
}

#notification-favorites-modal {
    .modal-content {
        background-color: #fefefe;
        margin: 15% auto;
        border: 1px solid rgba(color('primary'), 0.3);
        width: 450px;
        height: auto;

        @media(max-width: 768px) {
            width: 400px;
        }

        @media(max-width: 425px) {
            width: 320px;
        }

        .modal-top {
            padding: 10px 20px 10px 20px;
            background-color: color('primary');
            color: #eee;

            p {
                margin: 0;
                color: #fff;
                font-size: 20px;
                font-weight: 300;
                // width: 49.99%;
                line-height: 2;
            }

            button {
                color: #fff;

                span {
                    font-size: 40px;
                }
            }
        }

        #favorite_frequency {
            padding: 20px 20px 10px 20px;

            label {
                font-size: 15px;
            }
        }

        .save-favorite-frequency-btn {
            padding: 8px 18px;
            margin: 0 20px 20px;
            text-transform: uppercase;
            color: #fff;
            background-color: color('primary');
            font-size: 13px;
            font-weight: 300;

            i {
                padding-left: 10px;

                &::before {
                    content: "\f0c7";
                }
            }
        }
    }

    .favorites-notification-cancel-btn {
        float: right;
        margin-right: 20px;
        line-height: 35px;

        &:hover {
            color: #e74c3c;
            text-decoration: none;
        }

        i {
            margin-left: 0.5rem;
        }
    }
}

.notification-modal-message {
    position: fixed !important;
    background-color: #d4edda;

    &.alert {
        margin-left: auto;
        transform: translate(-50%, -50%);
    }

    &.alert-error {
        background-color: rgba(255, 0, 0, 1);
    }

    span {
        font-size: 15px;
        font-weight: 700;

        &.stored-name {
            line-break: anywhere;
        }
    }

    a {
        font-weight: 600;
    }

    button {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 30px;
        color: #6c6969;

        &:hover {
            color: #eeee;
        }
    }
}

.user-settings-module form[name="web_user"],
form[name="password_web_user"] {
    > div {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;

        @media(max-width: 768px) {
            flex-direction: row;
            justify-content: center;
        }

        .form-group {
            width: 47%;

            @media(max-width: 768px) {
                width: 100%;
            }
        }
    }
}